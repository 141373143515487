<template>
    <div class="table-container" :class="{ fullHeight: fullHeight }">
        <el-table :data="tableData" :height="calcTableHeight" :border="border">
            <template v-for="(column, index) in columns">
                <el-table-column
                    v-if="column.type == 'index'"
                    :key="index"
                    type="index"
                    :label="column.label"
                    :align="column.align || 'left'"
                    :width="column.width"
                    :index="
                        index => (page.page - 1) * page.pageSize + index + 1
                    "
                >
                </el-table-column>
                <el-table-column
                    v-else
                    :key="index"
                    :prop="column.prop"
                    :label="column.label"
                    :align="column.align || 'left'"
                    :width="column.width"
                    :min-width="column.minWidth"
                    :max-width="column.maxWidth"
                    :show-overflow-tooltip="column.tooltip || true"
                    :fixed="column.fixed"
                >
                    <template slot-scope="scope">
                        <slot
                            :name="column.prop"
                            :data="renderData(scope)"
                            :field="column.prop"
                            :value="scope.row[column.prop]"
                            :columnIndex="index"
                        >
                            <div>
                                {{ scope.row[column.prop] || "-" }}
                            </div>
                        </slot>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <el-pagination
            ref="tabel-pagination"
            class="tabel-pagination"
            background
            layout="prev, pager, next, jumper, sizes, total"
            :page-size="page.pageSize"
            :total="page.total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        >
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: "TableList",
    props: {
        fullHeight: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        border: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        query: {
            type: Object
        },
        tableData: {
            type: Array,
            default: function() {
                return [];
            }
        },
        columns: {
            type: Array,
            default: function() {
                return [];
            }
        },
        page: {
            type: Object,
            default: function() {
                return {
                    page: 1,
                    pageSize: 10
                };
            }
        },
        doHandler: {
            type: Function,
            required: true
        }
    },
    computed: {
        calcTableHeight() {
            return this.fullHeight ? "calc(100% - 45px)" : undefined;
        }
    },
    data() {
        return {
            queryCopy: {}
        };
    },
    methods: {
        renderData(scope) {
            scope.row.$index = scope.$index;
            return scope.row;
        },
        doQuery() {
            this.queryCopy = Object.assign({}, this.query);
            this.refreshTable({ page: 1 });
        },
        refreshTable(changePage) {
            let page = Object.assign({}, this.page, changePage);
            delete page.total;

            let params = Object.assign({}, this.queryCopy, page);

            this.doHandler(params).then(res => {
                page = {
                    page: Number.parseInt(res.data.data.current_page),
                    pageSize: Number.parseInt(res.data.data.per_page),
                    total: Number.parseInt(res.data.data.total)
                };
                this.$emit("update:tableData", res.data.data.data);
                this.$emit("update:page", page);
            });
        },
        handleCurrentChange(page) {
            this.refreshTable({ page });
        },
        handleSizeChange(pageSize) {
            this.refreshTable({ pageSize });
        }
    }
};
</script>
<style lang="scss" scoped>
.table-container {
    width: 100%;
    .tabel-pagination {
        margin-top: 10px;
        text-align: center;
    }
}
</style>
